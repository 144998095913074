import React from "react";
import configureStore from "./redux-store";
import {Provider} from "react-redux";
import {Helmet} from "react-helmet";
import App from "./App";
import config from "./config";
import {useClearCache} from "./hooks/useClearCache";
import {getCurrentUser, retrieveAuthFromStorage} from "./redux-actions/auth";
import {getPublicSettings} from "./redux-actions/settings";
import {preloadComponentsFor} from "./routing/routing-data";
const store = configureStore();

store.dispatch(getPublicSettings());
store.dispatch(retrieveAuthFromStorage());
if(store.getState()?.auth?.token) {
    store.dispatch(getCurrentUser())
    preloadComponentsFor(store.getState().auth.user?.role)
}

const AppLoader = () => {
    const {loading, isLatestVersion, refreshCacheAndReload} = useClearCache();
    if(loading) return null;

    if (!loading && !isLatestVersion) {
         refreshCacheAndReload();
         return null
    }

    return (
        <Provider store={store}>
            <Helmet>
                <title>{config.title}</title>
            </Helmet>

            <App/>
        </Provider>
    );
}

export default AppLoader;
