import React, {useEffect, useState} from 'react';
import {Badge, Button, Form, Input, message} from 'antd';
import styles from './Login.module.scss'
import {Link, useHistory, useLocation} from "react-router-dom";
import {tokenLogin, userLogin} from "../redux-actions/auth";
import querystring from "querystring"
import {LockOutlined, UserOutlined, LoginOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import config from '../config'

const FormItem = Form.Item;

const LoginForm = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [orcidWindow, setOrcidWindow] = useState({})
    const [form] = Form.useForm();

    useEffect(() => {
        const handleMessage = (e) => {
            const allowedOrigin = process.env.NODE_ENV !== 'production' ? 'http://localhost:9001' : 'https://auth.suberus.net'
            if( e.origin !== allowedOrigin ) return;

            if(orcidWindow.close) {
                orcidWindow.close();
                if(e.data.token) {
                    dispatch(tokenLogin(e.data.token))
                    e.data.firstTime ? history.push('/profile?orcid=true') : history.push('/profile')
                }
            }
        }

        window.addEventListener("message", handleMessage, false);

        return () => {
            document.removeEventListener('message', handleMessage)
        }}, [orcidWindow, dispatch, history])


    const onFinish = (values) => {
        setLoading(true)
        dispatch(userLogin({
            username: values.email,
            password: values.password
        }))
            .then(() => {
                const params = querystring.parse(location.search.substring(1))   // substring removes first '?'
                setLoading(false);
                history.push(params.redirect ? params.redirect : '/profile')
            })
            .catch((response) => {
                setLoading(false);
                message.error('Wrong email or password!')
            })
    }


    const loginWithOrcid = () => {
        const w = 460;
        const h = 715;
        const url = process.env.NODE_ENV !== 'production' ? `http://localhost:9001/orcid?state=${config.name}` : `https://auth.suberus.net/orcid?state=${config.name}`;
        const title = 'Login using ORCiD';
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const orcidWindow = window.open(url, title,
            `width=${w / systemZoom}, 
                    height=${h / systemZoom}, 
                    top=${top}, 
                    left=${left}`
        )

        if (window.focus) orcidWindow.focus();
        setOrcidWindow(orcidWindow)
    }

    return (
        <>
            <h1 className={styles.signInHeader}>Sign in</h1>
            <Form form={form} onFinish={onFinish}>
                <FormItem name="email" className={styles.formItem}
                          rules={[{required: true, message: 'Please input your email!'}]}
                >
                    <Input prefix={<UserOutlined className={styles.loginInputIcon}/>} placeholder="Email"/>
                </FormItem>
                <FormItem name="password" className={styles.formItem}
                          rules={[{required: true, message: 'Please input your Password!'}]}
                >
                    <Input prefix={<LockOutlined className={styles.loginInputIcon}/>} type="password"
                           placeholder="Password"/>
                </FormItem>
                <FormItem className={styles.formItem}>
                    <Button type="primary" htmlType="submit" className={styles.loginFormButton} loading={loading}
                            icon={<LoginOutlined/>}>
                        Log in
                    </Button>
                </FormItem>
                {/*<div className={styles.orDiv}>*/}
                {/*    — OR —*/}
                {/*</div>*/}
                {/*<FormItem className={styles.formItem}>*/}
                {/*    <Badge count='Beta' className={styles.loginFormButton} >*/}
                {/*    <Button className={styles.loginFormButton} onClick={() => loginWithOrcid()}>*/}
                {/*        Sign in with ORCiD*/}
                {/*    </Button>*/}
                {/*    </Badge>*/}
                {/*</FormItem>*/}
                <FormItem noStyle={true}>
                    Or <Link to="/register">register now!</Link>
                    <Link className={styles.loginFormForgot} to="/forgot-password">Forgot password</Link>
                </FormItem>
            </Form>
        </>
    );
}

export default LoginForm;
