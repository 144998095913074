import * as ORCID from 'orcid-utils'

export default (rule, value) => {
    if(!value) return Promise.resolve()

    if (ORCID.isValid(value)) {
        return Promise.resolve();
    }
    return Promise.reject('The ORCiD ID is not correct');
}
